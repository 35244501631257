import * as actions from "../api";
import { showMessage } from "../uiSlice";
import axios from "axios";

const defaultHeaders = {
    'X-Api-Key': process.env.REACT_APP_APP_TOKEN
};

const api = ({ dispatch }) => (next) => async (action) => {
    if (action.type !== actions.apiCallBegan.type) {
        return next(action);
    }

    const { url, method, data, headers, onStart, onSuccess, onError } = action.payload;

    if (onStart) {
        dispatch({ type: onStart });
    }

    next(action);

    try {
        const response = await axios.request({
            baseURL: `${process.env.REACT_APP_BASE_URL}/api/v1`,
            url,
            method,
            data,
            headers: { ...defaultHeaders, ...headers }
        });

        dispatch(actions.apiCallSuccess(response.data));

        if (onSuccess) {
            dispatch({ type: onSuccess, payload: response.data });
        }
    } catch (error) {
        dispatch(actions.apiCallFailed({ error: error.message }));
        dispatch(showMessage({ message: error?.response?.data?.error_message ?? "Something went wrong" }));

        if (onError) {
            dispatch({ type: onError, payload: { error: error.message } });
        }
    }
};

export default api;