import { combineReducers, configureStore } from '@reduxjs/toolkit'
import authSlice from './authSlice';
import uiSlice from './uiSlice';
import api from './middleware/api';
import upload from './middleware/upload';
import storage from 'redux-persist/lib/storage';
import programSlice from './programSlice';
import courseSlice from './courseSlice';
import lessonSlice from './lessonSlice';
import { uploadCallBegan } from './api';
import {
    persistStore, persistReducer, FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist';
import dashboardSlice from './dashboardSlice';
import websocketSlice from './websocketSlice';
import websocket from './middleware/websocket';
import fileSlice from './fileSlice';
import appConfigSlice from './appConfigSlice';
import opsSlice from './opsSlice';


const reducers = combineReducers({
    auth: authSlice,
    appConfig: appConfigSlice,
    program: programSlice,
    course: courseSlice,
    lesson: lessonSlice,
    dashboard: dashboardSlice,
    file: fileSlice,
    ui: uiSlice,
    websocket: websocketSlice,
    ops: opsSlice,
});

const persistConfig = {
    key: 'root',
    storage,
    // blacklist: ['tool']
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [
                FLUSH, 
                REHYDRATE, 
                PAUSE, 
                PERSIST, 
                PURGE, 
                REGISTER,
                uploadCallBegan.type,
            ],
        },
    }).concat(upload, api, websocket),
});

const persistor = persistStore(store);

export { store, persistor };