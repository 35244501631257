import { createSlice } from "@reduxjs/toolkit";
import * as actions from './api';

export const appConfigSlice = createSlice({
    name: 'appConfig',
    initialState: {
        loading: false,
        error: null,
        config: null,
    },
    reducers: {
        appConfigRequested: (state, action) => {
            state.loading = true;
        },
        appConfigReceived: (state, action) => {
            state.loading = false;
            action.payload.config.sections.forEach(section => {
                section.id = crypto.randomUUID()
            });
            state.config = action.payload.config;
        },
        appConfigUpserted: (state, action) => {
            state.loading = false;
            action.payload.config.sections.forEach(section => {
                section.id = crypto.randomUUID()
            });
            state.config = action.payload.config;
        },
        appConfigReordered: (state, action) => {
            state.loading = false;
            state.config = action.payload;
        },
        appConfigAddedEmptySection: (state, action) => {
            state.loading = false;
            state.config = action.payload;
        },
        appConfigDeleted: (state, action) => {
            state.loading = false;
            state.config = action.payload;
        },
        appConfigError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
    }
});

const {
    appConfigRequested,
    appConfigReceived,
    appConfigError,
    appConfigUpserted,
    appConfigReordered,
    appConfigAddedEmptySection,
    appConfigDeleted,
} = appConfigSlice.actions;

const getAppConfig = () => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken;
    dispatch(
        actions.apiCallBegan(
            {
                url: '/app_config',
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                },
                onStart: appConfigRequested.type,
                onSuccess: appConfigReceived.type,
                onError: appConfigError.type
            }
        )
    );
}

const upsertAppConfig = (data) => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken;

    dispatch(
        actions.apiCallBegan(
            {
                url: '/app_config',
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                },
                data: {
                    config: data
                },
                onStart: appConfigRequested.type,
                onSuccess: appConfigUpserted.type,
                onError: appConfigError.type
            }
        )
    );
}

const reorderAppConfigSections = (data) => (dispatch, getState) => {
    dispatch(appConfigReordered(data));
    dispatch(upsertAppConfig(getState().appConfig.config));
}

const addSection = () => (dispatch, getState) => {
    let currentConfig = getState().appConfig.config;
    let data = {
        sections: [
            ...currentConfig.sections,
            {
                id: crypto.randomUUID(),
                name: "",
                description: "",
                dataType: null,
                condition: null,
                display: null,
            },
        ]
    };
    dispatch(appConfigAddedEmptySection(data));
}

const deleteSection = (index) => (dispatch, getState) => {
    let currentConfig = getState().appConfig.config;
    var newSections = currentConfig.sections.filter((item, itemIndex) => index != itemIndex);
    let data = {
        sections: newSections
    };
    dispatch(appConfigDeleted(data));
}

export { 
    getAppConfig, 
    upsertAppConfig,
    reorderAppConfigSections,
    addSection,
    deleteSection,
};

export default appConfigSlice.reducer;