export const Statuses = [
    { value: "active", label: "Active" },
    { value: "inactive", label: "Inactive" },
]

export const StatusFromValue = (value) => {
    return Statuses.filter((item) => item.value === value)[0];
}

export const Difficulties = [  
    { value: "easy", label: "Easy" },
    { value: "medium", label: "Medium" },
    { value: "advanced", label: "Advanced" },
]

export const DifficultyFromValue = (value) => {
    return Difficulties.filter((item) => item.value === value)[0];
}

export const Displays = [  
    { value: "vlist", label: "Vertical List" },
    { value: "hlist", label: "Horizontal List" },
    { value: "grid2", label: "Grid 2" },
    { value: "grid3", label: "Grid 3" },
    { value: "grid4", label: "Grid 4" },
]

export const Conditions = [
    { value: "featured", label: "Featured" },
    { value: "hot", label: "Hot" },
    { value: "all", label: "All" },
    { value: "perday", label: "Daily New Items" },
];

export const DataTypes = [
    { value: "lessons", label: "Lessons" },
    { value: "courses", label: "Courses" },
    { value: "programs", label: "Programs" },
];