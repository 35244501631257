import { createSlice } from "@reduxjs/toolkit";
import * as actions from './api';

export const programSlice = createSlice({
    name: 'program',
    initialState: {
        loading: false,
        error: null,
        list: [],
        form: null,
        popup: false,
    },
    reducers: {
        programsRequested: (state, action) => {
            state.loading = true;
        },
        programsReceived: (state, action) => {
            state.loading = false;
            state.list = action.payload.data;
        },
        programsError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        programRequested: (state, action) => {
            state.loading = true;
        },
        programCreated: (state, action) => {
            state.loading = false;
            state.popup = false;
            state.list = [action.payload, ...state.list];
            state.form = null;
        },
        programUpdated: (state, action) => {
            state.loading = false;
            state.popup = false;
            state.form = null;
            const index = state.list.findIndex( program => program.id === action.payload.id );
            state.list[index] = action.payload;
        },
        programDeleted: (state, action) => {
            state.loading = false;
            state.list = state.list.filter( program => program.id !== action.payload.id );
        },
        programError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        showProgramPopup: (state, action) => {
            state.popup = true;
            state.form = action.payload;
        },
        closePopup: (state, action) => {
            state.popup = false;
            state.form = null;
        }
    }
});

const {
    programsRequested,
    programsReceived,
    programsError,
    programRequested,
    programError,
    programCreated,
    programUpdated,
    programDeleted,
    showProgramPopup,
    closePopup,
} = programSlice.actions;

const getPrograms = () => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken;
    dispatch(
        actions.apiCallBegan(
            {
                url: '/programs',
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                },
                onStart: programsRequested.type,
                onSuccess: programsReceived.type,
                onError: programsError.type
            }
        )
    );
}

const createProgram = (data) => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken;

    dispatch(
        actions.apiCallBegan(
            {
                url: '/programs',
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                },
                data: data,
                onStart: programRequested.type,
                onSuccess: programCreated.type,
                onError: programError.type
            }
        )
    );
}

const updateProgram = (data) => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken;

    dispatch(
        actions.apiCallBegan(
            {
                url: '/programs/' + data.id,
                method: 'PUT',
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                },
                data: data,
                onStart: programRequested.type,
                onSuccess: programUpdated.type,
                onError: programError.type
            }
        )
    );
}

const deleteProgram = (data) => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken;

    dispatch(
        actions.apiCallBegan(
            {
                url: '/programs/' + data.id,
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                },
                onStart: programRequested.type,
                onSuccess: programDeleted.type,
                onError: programError.type
            }
        )
    );
}

export { 
    getPrograms, 
    createProgram, 
    updateProgram, 
    deleteProgram, 
    showProgramPopup, 
    closePopup 
};

export default programSlice.reducer;