import * as actions from "../api";
import { showMessage } from "../uiSlice";
import axios from "axios";
import * as FormData from 'form-data';

const defaultHeaders = {
    'X-Api-Key': process.env.REACT_APP_APP_TOKEN,
    'Content-Type': 'multipart/form-data',
};

const upload = ({ dispatch }) => (next) => async (action) => {
    if (action.type !== actions.uploadCallBegan.type) {
        return next(action);
    }

    const { url, data, headers, onStart, onSuccess, onError } = action.payload;

    if (onStart) {
        dispatch({ type: onStart });
    }

    next(action);

    try {
        var formData = new FormData();
        formData.append("image", data);

        for (var key of formData.entries()) {
            console.log(key[0] + ', ' + key[1]);
        }
    
        const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/v1${url}`,
            formData,
            { 
                headers: {
                    ...defaultHeaders, 
                    ...headers,
                }
            },
        );

        dispatch(actions.uploadCallSuccess(response.data));

        if (onSuccess) {
            dispatch({ type: onSuccess, payload: response.data });
        }
    } catch (error) {
        dispatch(actions.uploadCallFailed({ error: error.message }));
        dispatch(showMessage({ message: error?.response?.data?.error_message ?? "Something went wrong" }));

        if (onError) {
            dispatch({ type: onError, payload: { error: error.message } });
        }
    }
};

export default upload;