import { Link, useRouteMatch } from "react-router-dom";
import Icon from "./common/Icon";

export default function Sidebar() {
    return (
        <div className="sidebar">
            <Link to="/" className="sidebar-brand">
                <img className="brand-img" src="/img/logo.svg" alt="logo" />
            </Link>

            <nav className="sidebar-content">
                <ul>
                    <li>
                        <SideNavItem title="Dashboard" icon="monitor" path="/" />
                    </li>
                    <li>
                        <SideNavItem title="App Config" icon="smartphone" path="/app_config" />
                    </li>
                    <li>
                        <SideNavItem title="Programs" icon="box" path="/programs" />
                    </li>
                    <li>
                        <SideNavItem title="Courses" icon="book-open" path="/courses" />
                    </li>
                    <li>
                        <SideNavItem title="Lessons" icon="check-square" path="/lessons" />
                    </li>
                    <li>
                        <SideNavItem title="System" icon="tool" path="/system" />
                    </li>

                </ul>
            </nav>
        </div>
    );
}

function SideNavItem({ title, icon, path }) {

    let match = useRouteMatch({
        path: path,
        exact: true,
        strict: false
    });

    return (
        <Link to={path} className={match ? "active" : ""}>
            <Icon icon={icon} size="16" />
            {title}
        </Link>
    );
}