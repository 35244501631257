import { createSlice } from "@reduxjs/toolkit";
import * as actions from './api';

export const opsSlice = createSlice({
    name: "ops",
    initialState: {
        loading: false,
        error: null,
    },
    reducers: {
        opsRequested: (state, action) => {
            state.loading = true;
        },
        opsReceived: (state, action) => {
            state.loading = false;
        },
        opsError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
    }
});

const {
    opsRequested,
    opsReceived,
    opsError
} = opsSlice.actions;

const clearCache = () => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken;
    dispatch(
        actions.apiCallBegan(
            {
                url: `/ops/cache/clear`,
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                },
                onStart: opsRequested.type,
                onSuccess: opsReceived.type,
                onError: opsError.type
            }
        )
    );
}

export { clearCache };

export default opsSlice.reducer;

