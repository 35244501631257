import { websocketActions } from '../websocketSlice';

const websocket = ({ dispatch }) => {
    let socket;
    return next => action => {
        if (!websocketActions.connect.match(action)) {
            return next(action);
        }

        socket = new WebSocket(process.env.REACT_APP_WS);

        socket.onopen = () => {
            console.log("connecting");
            dispatch(websocketActions.connected());
        };

        socket.onmessage = (message) => {
            console.log(message.data);
            dispatch(websocketActions.receiveMessage(message.data));
        };

        socket.onclose = (event) => {
            console.log(event);
        }

        socket.onerror = (error) => {
            console.log(error);
        };

        next(action);
    }
};

export default websocket;