import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showProgramPopup, getPrograms, deleteProgram, updateProgram } from '../../store/programSlice';
import Icon from '../common/Icon';
import ProgramPopup from './ProgramPopup';
import InlinePopup from '../common/InlinePopup';
import { StatusFromValue, DifficultyFromValue } from '../../domain/Constants';

export default function Programs() {

    let [showingDelete, setShowDelete] = useState(false);
    let [selected, setSelected] = useState(null);
    let list = useSelector(state => state.program.list);

    let dispatch = useDispatch();

    useEffect(() => {
        dispatch(getPrograms());
    }, [dispatch])

    const createNewProgram = (e) => {
        e.preventDefault();
        dispatch(showProgramPopup());
    };

    const toggleFeatured = (program) => (e) => {
        e.preventDefault();
        dispatch(
            updateProgram({
                ...program,
                featured: !program.featured,
            })
        );
    }

    const editProgramClick = (program) => (e) => {
        e.preventDefault();
        dispatch(showProgramPopup(program));
    }

    const deleteProgramClick = (program) => (e) => {
        e.preventDefault();
        setSelected(program);
        setShowDelete(true);
    }

    const hideDeletePopup = (e) => {
        e.preventDefault();
        setShowDelete(false);
    };

    const renderRows = () => {
        if (list.length === 0) {
            return (
                <tr key="0">
                    <td colSpan="4">No data</td>
                </tr>
            );
        }
        return list.map((program, index) => {
            return (
                <tr key={index}>
                    <td>{index + 1}</td>
                    <td><img alt={program.image} src={program.image} className="thumbnail"></img></td>
                    <td>{program.name}</td>
                    <td>{program.description}</td>
                    <td>{DifficultyFromValue(program.difficulty).label}</td>
                    <td>{StatusFromValue(program.status).label}</td>
                    <td className="table-actions">
                        <ul className="action-list">
                            <li className="action-item">
                                <a href="/#" onClick={toggleFeatured(program)}>
                                    <Icon 
                                        icon="star" 
                                        fillColor={program.featured === true ? "#f1c40f" : "none"} 
                                        color={program.featured === true ? "#f1c40f" : "currentColor"} 
                                        />
                                </a>
                            </li>

                            <li className="action-item">
                                <a href="/#" onClick={editProgramClick(program)}>
                                    <Icon icon="edit-2" color={"#3498db"} />
                                </a>
                            </li>

                            <li className="action-item">
                                <a href="/#" onClick={deleteProgramClick(program)}>
                                    <Icon icon="trash" color={"#dc3545"} />
                                </a>
                            </li>
                        </ul>
                    </td>
                </tr>
            );
        });
    };

    return (
        <div className="card">
            <div className="card-header">
                <h5 className="card-title">Programs</h5>

                <div className="card-actions">
                    <a href="/#" className="btn btn-primary" onClick={createNewProgram}>
                        <Icon size="16" icon="plus" />
                        Create New Program
                    </a>
                </div>
            </div>

            <table className="table">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Image</th>
                        <th className="sortable sort-asc">Program Name</th>
                        <th>Description</th>
                        <th>Difficulty</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {renderRows()}
                </tbody>
            </table>

            <ProgramPopup />

            <InlinePopup
                isShowing={showingDelete}
                hide={hideDeletePopup}
                title="Delete Program"
                message="Are you sure you want to delete this program?"
                action={deleteProgram(selected)} />
        </div>
    );
}