import { createSlice } from "@reduxjs/toolkit";

export const uiSlice = createSlice({
    name: "ui",
    initialState: {
        popup: {},
        message: null,
        loading: false
    },
    reducers: {
        showPopup: (state, action) => {
            state.popup = { 
                ...action.payload,
                show: true
            };
        },
        hidePopup: (state, action) => {
            state.popup = {};
        },
        showMessage: (state, action) => {
            state.message = {
                message: action.payload.message
            };
        },
        hideMessage: (state) => {
            state.message = null;
        },
        showLoading: (state) => {
            state.loading = true;
        },
        hideLoading: (state) => {
            state.loading = false;
        }
    }
});

const {
    showPopup,
    hidePopup,
    showLoading,
    hideLoading,
    showMessage,
    hideMessage,
} = uiSlice.actions;

const confirmLogout = (action) => (dispatch) => {
    dispatch(showPopup({
        title: "Logout",
        message: "Are you sure you want to log out?",
        action: action
    }));
}

export { 
    confirmLogout, 
    showPopup, 
    hidePopup, 
    showLoading, 
    hideLoading,
    showMessage,
    hideMessage
};

export default uiSlice.reducer;
