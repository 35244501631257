import { createSlice } from "@reduxjs/toolkit";
import * as actions from './api';

export const courseSlice = createSlice({
    name: 'course',
    initialState: {
        loading: false,
        error: null,
        list: [],
        form: null,
        popup: false,
    },
    reducers: {
        coursesRequested: (state, action) => {
            state.loading = true;
        },
        coursesReceived: (state, action) => {
            state.loading = false;
            state.list = action.payload.data;
        },
        coursesError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        courseRequested: (state, action) => {
            state.loading = true;
        },
        courseCreated: (state, action) => {
            state.loading = false;
            state.popup = false;
            state.list = [action.payload, ...state.list];
            state.form = null;
        },
        courseUpdated: (state, action) => {
            state.loading = false;
            state.popup = false;
            state.form = null;
            const index = state.list.findIndex( course => course.id === action.payload.id );
            state.list[index] = action.payload;
        },
        courseDeleted: (state, action) => {
            state.loading = false;
            state.list = state.list.filter( course => course.id !== action.payload.id );
        },
        courseError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        showCoursePopup: (state, action) => {
            state.popup = true;
            state.form = action.payload;
        },
        closePopup: (state, action) => {
            state.popup = false;
            state.form = null;
        }
    }
});

const {
    coursesRequested,
    coursesReceived,
    coursesError,
    courseRequested,
    courseError,
    courseCreated,
    courseUpdated,
    courseDeleted,
    showCoursePopup,
    closePopup,
} = courseSlice.actions;

const getCourses = () => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken;
    dispatch(
        actions.apiCallBegan(
            {
                url: '/courses',
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                },
                onStart: coursesRequested.type,
                onSuccess: coursesReceived.type,
                onError: coursesError.type
            }
        )
    );
}

const createCourse = (data) => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken;

    dispatch(
        actions.apiCallBegan(
            {
                url: '/courses',
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                },
                data: data,
                onStart: courseRequested.type,
                onSuccess: courseCreated.type,
                onError: courseError.type
            }
        )
    );
}

const updateCourse = (data) => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken;

    dispatch(
        actions.apiCallBegan(
            {
                url: '/courses/' + data.id,
                method: 'PUT',
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                },
                data: data,
                onStart: courseRequested.type,
                onSuccess: courseUpdated.type,
                onError: courseError.type
            }
        )
    );
}

const deleteCourse = (data) => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken;

    dispatch(
        actions.apiCallBegan(
            {
                url: '/courses/' + data.id,
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                },
                onStart: courseRequested.type,
                onSuccess: courseDeleted.type,
                onError: courseError.type
            }
        )
    );
}

export { 
    getCourses, 
    createCourse, 
    updateCourse, 
    deleteCourse, 
    showCoursePopup, 
    closePopup 
};

export default courseSlice.reducer;