import { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAppConfig, upsertAppConfig, reorderAppConfigSections, addSection, deleteSection } from '../../store/appConfigSlice';
import {
    DndContext,
    closestCenter,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { SortableSection } from './SortableSection';
import { useFormik } from 'formik';
import { MouseSensor } from 'components/common/DragAndDrop';
import Icon from '../common/Icon';

export default function AppConfig() {
    let loading = useSelector(state => state.appConfig.loading);
    let [dirty, setDirty] = useState(false);
    let config = useSelector(state => state.appConfig.config);
    let sensors = useSensors(
        useSensor(MouseSensor)
    );

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            sections: config?.sections ?? [],
        },
        validate: values => {
            const errors = {};
            if (!values.sections || values.sections.length === 0) {
                errors.sections = 'Required';
            }

            return errors;
        },
        onSubmit: (values, { resetForm }) => {
            dispatch(upsertAppConfig(values));
        }
    });

    let dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAppConfig());
    }, [dispatch]);

    useEffect(() => {
        if (formik.initialValues.sections?.length ?? 0 > 0) {
            if (formik.initialValues.sections != formik.values.sections) {
                setDirty(true);
            }
        }
    }, [formik.values]);

    useEffect(() => {
        formik.setFieldValue("sections", config?.sections);
    }, [config]);

    let sortedItems = useMemo(() => config?.sections?.map(section => section.id) ?? [], [config]);

    const onSave = (e) => {
        e.preventDefault();
        formik.submitForm();
    };

    const addNewSection = (e) => {
        e.preventDefault();
        setDirty(true);
        dispatch(addSection());
    };

    const deleteExistingSection = (index) => {
        dispatch(deleteSection(index));
        setDirty(true);
    };

    const onDragStart = () => {
    };

    const onDragEnd = (event) => {
        const {active, over} = event;

        let sections = config?.sections;
        if (sections == null) {
            return;
        }

        if (active.id !== over.id) {
            const oldIndex = sections.findIndex(section => section.id == active.id);
            const newIndex = sections.findIndex(section => section.id == over.id);
            const newSections = arrayMove(sections, oldIndex, newIndex);
            dispatch(
                reorderAppConfigSections(
                    {
                        sections: newSections
                    }
                )
            );
        }
      }

    const renderSections = () => {
        return (
            <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={onDragEnd}
                onDragStart={onDragStart}
            >
                <SortableContext
                    items={sortedItems}
                    strategy={verticalListSortingStrategy}
                >
                    {config?.sections.map((section, index) =>
                        <SortableSection 
                            key={section.id} 
                            section={section} 
                            index={index} 
                            formik={formik}
                            removeItem={deleteExistingSection}
                        />)
                    }
                </SortableContext>
            </DndContext>);
    };

    return (
        <div className="card">
            <div className="card-header">
                <h5 className="card-title">AppConfig</h5>

                <div className="card-actions">
                    { dirty &&
                    <a href="/#" className="btn btn-primary" onClick={onSave}>
                        <Icon size="16" icon="save" className="mr-2"/>
                        Save
                    </a>
                    }
                </div>
            </div>
            <div className='card-body'>
                <form>
                    { renderSections() }
                </form>
                <div className="app-config-section-add mt-2">
                    <a href="/#" className="btn btn-primary" onClick={addNewSection}>
                        <Icon size="16" icon="plus" className="mr-2"/>
                        Add New Section
                    </a>
                </div>
            </div>

        </div>
    );
}