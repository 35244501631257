import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closePopup, createCourse, updateCourse } from '../../store/courseSlice';
import { createFile, reset } from '../../store/fileSlice';
import { getPrograms } from '../../store/programSlice';
import Icon from "../common/Icon";
import Loading from '../common/Loading';
import Dropdown from '../common/Dropdown';
import { useFormik } from 'formik';
import { Statuses, Difficulties } from '../../domain/Constants';

const CoursePopup = () => {

    let loading = useSelector(state => state.course.loading);
    let popup = useSelector(state => state.course.popup);
    let form = useSelector(state => state.course.form);
    let programList = useSelector(state => state.program.list);
    let programOptions = programList.map((item) => { return { value: `${item.id}`, label: item.id + "-" + item.name } }) || [];
    let file = useSelector(state => state.file.file);
    let fileRef = useRef();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getPrograms());
    }, [dispatch])

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            description: form ? form.description : '',
            imageId: form ? form.imageId : null,
            name: form ? form.name : '',
            difficulty: form ? form.difficulty : Difficulties[0].value,
            featured: form ? form.featured : '',
            program_id: form ? `${form.program_id}` : '',
            status: form ? form.status : Statuses[0].value,
        },
        validate: values => {
            const errors = {};
            if (!values.name || values.name.length === 0) {
                errors.name = 'Required';
            }

            if (!values.description || values.description.length === 0) {
                errors.description = 'Required';
            }

            if (!values.difficulty || values.difficulty.length === 0) {
                errors.difficulty = 'Required';
            }

            return errors;
        },
        onSubmit: (values, { resetForm }) => {
            onSubmit(values);
            fileRef.current.value = null;
            resetForm();
        }
    });

    useEffect(() => {
        let imageId = file?.id;
        console.log(imageId);
        formik.setFieldValue("imageId", imageId);
    }, [file]);

    const close = (e) => {
        e.preventDefault();
        dispatch(closePopup());
        dispatch(reset);
        fileRef.current.value = null;
    };

    const onUploadFile = (event) => {
        let file = event.target.files[0];
        dispatch(
            createFile(
                file
            )
        );
    }

    const onSubmit = (values) => {
        if (form) {
            dispatch(updateCourse({
                id: form.id,
                description: values.description,
                imageId: values.imageId,
                name: values.name,
                featured: values.featured,
                difficulty: values.difficulty,
                program_id: parseInt(values.program_id),
                status: values.status,
            }));
            return;
        }

        dispatch(createCourse({
            description: values.description,
            imageId: values.imageId,
            name: values.name,
            difficulty: values.difficulty,
            program_id: parseInt(values.program_id),
            status: values.status,
        }));
    };

    return (
        <div className={"popup popup-large" + (popup ? " popup-show" : "")}>
            <div className="popup-overlay"></div>

            <div className="popup-wrapper">
                <div className="popup-content">
                    <div className="card">
                        <div className="card-header">
                            {form ?
                                <h5 className="card-title">Edit Course</h5>
                                :
                                <h5 className="card-title">Create New Course</h5>
                            }
                            <div>
                                <a href="/#" onClick={close}>
                                    <Icon icon="x" />
                                </a>

                            </div>
                        </div>

                        <div className="card-body">
                            <form onSubmit={formik.handleSubmit} >
                                <div className="form-group">
                                    <label>Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="name"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.name}
                                    />
                                </div>

                                <div className="form-group">
                                    <label>Difficulty</label>
                                    <Dropdown
                                        options={Difficulties}
                                        name="difficulty"
                                        readOnly={form}
                                        onChange={selected => formik.handleChange("difficulty")(selected.value)}
                                        onBlur={formik.handleBlur}
                                        value={Difficulties.filter((option) => option.value === formik.values.difficulty)[0]}
                                    />
                                </div>

                                <div className="form-group">
                                    <label>Program</label>
                                    <Dropdown
                                        options={programOptions}
                                        name="program_id"
                                        onChange={selected => formik.handleChange("program_id")(selected.value)}
                                        onBlur={formik.handleBlur}
                                        value={programOptions.filter((option) => option.value === formik.values.program_id)[0] || null}
                                    />
                                </div>

                                <div className="form-group">
                                    <label>Description</label>
                                    <textarea
                                        type="text"
                                        rows={4}
                                        className="form-control"
                                        name="description"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.description}
                                    />
                                </div>

                                <div className="form-group">
                                    <label>Image</label>
                                    <input
                                        type="file"
                                        className="form-control"
                                        name="image"
                                        ref={fileRef}
                                        readOnly={form}
                                        onChange={onUploadFile}
                                    />
                                </div>

                                <div className="form-group">
                                    <label>Status</label>
                                    <Dropdown
                                        options={Statuses}
                                        name="status"
                                        onChange={selected => formik.handleChange("status")(selected.value)}
                                        onBlur={formik.handleBlur}
                                        value={Statuses.filter((option) => option.value === formik.values.status)[0]}
                                    />
                                </div>


                                <div className="actions">
                                    <button
                                        type="submit"
                                        className="btn btn-primary">
                                        {form ? "Update" : "Create"}
                                    </button>
                                    <div className="fill"></div>

                                    {loading &&
                                        <Loading />
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default CoursePopup;