import { useDispatch, useSelector } from 'react-redux';
import { clearCache } from '../../store/opsSlice';
import Loading from '../common/Loading';

const CacheCard = ({dispatch, loading = false, color = "primary"}) => {
    const clearCacheFunc = () => dispatch(clearCache());
    return (
        <div className="card card-dashboard-fig">
            <div className="card-body">
                <div className="fig-wrapper">
                    { loading ?
                        <Loading />
                        :
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={clearCacheFunc}
                            >
                            Clear Backend Cache
                        </button>
                    }
                </div>
                
            </div>
        </div>
    );
};

export default function System() {

    let {
        loading
    } = useSelector( state => state.ops );

    let dispatch = useDispatch();

    return (
        <div className="container-fluid">
            <div className="row mt-3 mb-3">
                <div className="col">
                    <h3>System</h3>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <CacheCard loading={loading} dispatch={dispatch}/>
                </div>
            </div> 
        </div>
    );
}