import { useDispatch, useSelector } from "react-redux";
import { closePopup, createProgram, updateProgram } from '../../store/programSlice';
import { createFile, reset } from '../../store/fileSlice';
import Icon from "../common/Icon";
import Loading from '../common/Loading';
import Dropdown from '../common/Dropdown';
import { useFormik } from 'formik';
import { Statuses, Difficulties } from '../../domain/Constants';
import { useEffect, useRef } from "react";

const ProgramPopup = () => {

    let loading = useSelector(state => state.program.loading);
    let popup = useSelector(state => state.program.popup);
    let form = useSelector(state => state.program.form);
    let file = useSelector(state => state.file.file);
    let fileRef = useRef();

    const dispatch = useDispatch();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            description: form ? form.description : '',
            imageId: form ? form.imageId : null,
            name: form ? form.name : '',
            featured: form ? form.featured : false,
            difficulty: form ? form.difficulty : Difficulties[0].value,
            status: form ? form.status : Statuses[0].value, 
        },
        validate: values => {
            const errors = {};

            if (!values.name || values.name.length === 0) {
                errors.name = 'Required';
            }

            if (!values.description || values.description.length === 0) {
                errors.description = 'Required';
            }

            if (!values.difficulty || values.difficulty.length === 0) {
                errors.difficulty = 'Required';
            }

            return errors;
        },
        onSubmit: (values, { resetForm }) => {
            onSubmit(values);
            fileRef.current.value = null;
            resetForm();
        }
    });

    useEffect(() => {
        let imageId = file?.id;
        console.log(imageId);
        formik.setFieldValue("imageId", imageId);
    }, [file]);

    const close = (e) => {
        e.preventDefault();
        dispatch(closePopup());
        dispatch(reset);
        fileRef.current.value = null;
    };

    const onSubmit = () => {
        let values = formik.values;
        if (form) {
            dispatch(
                updateProgram({
                    id: form.id,
                    description: values.description,
                    difficulty: values.difficulty,
                    featured: values.featured,
                    imageId: values.imageId,
                    name: values.name,
                    status: values.status,
                })
            );
            return;
        }

        dispatch(
            createProgram({
                description: values.description,
                difficulty: values.difficulty,
                imageId: values.imageId,
                name: values.name,
                status: values.status,
            })
        );
    }

    const onUploadFile = (event) => {
        let file = event.target.files[0];
        dispatch(
            createFile(
                file
            )
        );
    }

    return (
        <div className={"popup popup-large" + (popup ? " popup-show" : "")}>
            <div className="popup-overlay"></div>

            <div className="popup-wrapper">
                <div className="popup-content">
                    <div className="card">
                        <div className="card-header">
                            {form ?
                                <h5 className="card-title">Edit Program</h5>
                                :
                                <h5 className="card-title">Create New Program</h5>
                            }
                            <div>
                                <a href="/#" onClick={close}>
                                    <Icon icon="x" />
                                </a>

                            </div>
                        </div>

                        <div className="card-body">
                            <form onSubmit={formik.handleSubmit} >
                                <div className="form-group">
                                    <label>Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="name"
                                        readOnly={form}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.name}
                                    />
                                </div>

                                <div className="form-group">
                                    <label>Difficulty</label>
                                    <Dropdown
                                        options={Difficulties}
                                        name="difficulty"
                                        readOnly={form}
                                        onChange={selected => formik.handleChange("difficulty")(selected.value)}
                                        onBlur={formik.handleBlur}
                                        value={Difficulties.filter((option) => option.value === formik.values.difficulty)[0]}
                                    />
                                </div>

                                <div className="form-group">
                                    <label>Description</label>
                                    <textarea
                                        type="text"
                                        rows={4}
                                        className="form-control"
                                        name="description"
                                        readOnly={form}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.description}
                                    />
                                </div>

                                <div className="form-group">
                                    <label>Image</label>
                                    <input
                                        type="file"
                                        className="form-control"
                                        name="image"
                                        ref={fileRef}
                                        readOnly={form}
                                        onChange={onUploadFile}
                                    />
                                </div>

                                <div className="form-group">
                                    <label>Status</label>
                                    <Dropdown
                                        options={Statuses}
                                        name="status"
                                        readOnly={form}
                                        onChange={selected => formik.handleChange("status")(selected.value)}
                                        onBlur={formik.handleBlur}
                                        value={Statuses.filter((option) => option.value === formik.values.status)[0]}
                                    />
                                </div>

                                <div className="actions">
                                    <button
                                        type="submit"
                                        className="btn btn-primary">
                                        {form ? "Update" : "Create"}
                                    </button>
                                    <div className="fill"></div>

                                    {loading &&
                                        <Loading />
                                    }
                                </div>
                            </form>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProgramPopup;