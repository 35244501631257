import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getStat } from '../../store/dashboardSlice';
import Icon from '../common/Icon';
import Loading from '../common/Loading';

const DashboardCard = ({title, value, icon, loading = false, color = "primary"}) => {
    return (
        <div className="card card-dashboard-fig">
            <div className="card-body">
                <div className="fig-wrapper">
                    { loading ?
                        <Loading />
                        :
                        <div className="fig">
                            <h3>{value || '--'}</h3>
                            <p>{title}</p>
                        </div>
                    }
                        
                    <div className={"illus illus-" + color}>
                        <Icon icon={icon} size="24" />
                    </div>
                </div>
                
            </div>
        </div>
    );
};

export default function Dashboard() {

    let {
        lessonCount, 
        courseCount, 
        programCount, 
        loading
    } = useSelector( state => state.dashboard );

    let dispatch = useDispatch();

    useEffect(() => dispatch(getStat()), [dispatch]);

    return (
        <div className="container-fluid">
            <div className="row mt-3 mb-3">
                <div className="col">
                    <h3>Dashboard</h3>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <DashboardCard title="Lessons" value={lessonCount} loading={loading} icon="book-open" color="danger"/>
                </div>

                <div className="col">
                    <DashboardCard title="Courses" value={courseCount} loading={loading} icon="book" color="warning"/>
                </div>

                <div className="col">
                    <DashboardCard title="Programs" value={programCount} loading={loading} icon="award" color="success" />
                </div>
            </div> 
        </div>
    );
}