import Icon from "./Icon";
import { useDispatch, useSelector } from "react-redux";
import { hideMessage } from "../../store/uiSlice";

const MessageBlock = () => {
    let message = useSelector(state => state.ui.message);
    let dispatch = useDispatch();

    if (message) {
        setTimeout(() => {
            close();
        }, 3000);
    }

    const close = (e) => {
        e?.preventDefault();
        dispatch(hideMessage());
    };

    return (
        <div className={ "message-block" + (message ? " message-block--show" : "") }>
            <div className="message-block-content">
                <div className="card">
                    <div className="card-body">
                        <p>{message?.message}</p>
                        <a href="#" className="action" id="close" onClick={close}>
                            <Icon icon="x" size="18" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MessageBlock;