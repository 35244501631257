export default function Icon({ size = 16, icon, fillColor, color, ...props }) {
    return (
        <svg
            width={size}
            height={size}
            fill={ fillColor ?? "none" }
            stroke={ color ?? "currentColor"}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            {...props}
        >
            <use xlinkHref={"/img/feather-sprite.svg#" + icon} />
        </svg>
    );
};