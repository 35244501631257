import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Login from './components/Login';
import AdminPortal from './components/AdminPortal';
import MessageBlock from './components/common/MessageBlock';

function App() {
  return (
    <Router>
        <Switch>
            <Route path="/login">
                <Login />
            </Route>

            <Route path="/">
                <AdminPortal />
            </Route>
        </Switch>

        <MessageBlock />
    </Router>
  );
}

export default App;
