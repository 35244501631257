import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import Dropdown from '../common/Dropdown';
import { DataTypes, Displays, Conditions } from '../../domain/Constants';
import Icon from '../common/Icon';

export function SortableSection(props) {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id: props.section.id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    const formik = props.formik;
    const index = props.index;

    const removeItem = (e) => {
        e.preventDefault();
        props.removeItem(index);
    };

    if (formik.values.sections === null || formik.values.sections[index] === null) {
        return null;
    }

    return (
        <div className='app-config-section' ref={setNodeRef} style={style} {...attributes} {...listeners}>
            <div className="section-header">
                <h5 className="section-title">
                    { formik.values.sections[index]?.name }
                </h5>

                <div className="section-actions">
                    <a href="/#" data-no-dnd="true" className="btn btn-danger" onClick={removeItem}>
                        <Icon size="16" icon="trash" className="mr-2"/>
                        Delete
                    </a>
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <div className="form-group">
                        <label>Name</label>
                        <input
                            data-no-dnd="true"
                            type="text"
                            className="form-control"
                            name={`sections.${index}.name`}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.sections[index]?.name}
                        />
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-group">
                        <label>Data Type</label>
                        <Dropdown
                            data-no-dnd="true"
                            options={DataTypes}
                            name={`sections.${index}.dataType`}
                            onChange={selected => formik.handleChange(`sections.${index}.dataType`)(selected.value)}
                            onBlur={formik.handleBlur}
                            value={DataTypes.filter((option) => option.value === formik.values.sections[index]?.dataType)[0]}
                        />
                    </div>

                </div>
            </div>

            <div className="row">
                <div className="col-6">

                    <div className="form-group">
                        <label>Condition</label>
                        <Dropdown
                            data-no-dnd="true"
                            options={Conditions}
                            name={`sections.${index}.condition`}
                            onChange={selected => formik.handleChange(`sections.${index}.condition`)(selected.value)}
                            onBlur={formik.handleBlur}
                            value={Conditions.filter((option) => option.value === formik.values.sections[index]?.condition)[0]}
                        />
                    </div>
                </div>

                <div className="col-6">
                    <div className="form-group">
                        <label>Display</label>
                        <Dropdown
                            data-no-dnd="true"
                            options={Displays}
                            name={`sections.${index}.display`}
                            onChange={selected => formik.handleChange(`sections.${index}.display`)(selected.value)}
                            onBlur={formik.handleBlur}
                            value={Displays.filter((option) => option.value === formik.values.sections[index]?.display)[0]}
                        />
                    </div>
                </div>
            </div>

            <div className="form-group">
                <label>Description</label>
                <input
                    data-no-dnd="true"
                    type="text"
                    className="form-control"
                    name={`sections.${index}.description`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.sections[index]?.description}
                />
            </div>

        </div>
    );
}