import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showLessonPopup, getLessons, deleteLesson, updateLesson } from '../../store/lessonSlice';
import Icon from '../common/Icon';
import LessonPopup from './LessonPopup';
import InlinePopup from '../common/InlinePopup';
import { StatusFromValue, DifficultyFromValue } from '../../domain/Constants';

export default function Lessons() {

    let [showingDelete, setShowDelete] = useState(false);
    let [selected, setSelected] = useState(null);
    let list = useSelector(state => state.lesson.list);

    let dispatch = useDispatch();

    useEffect(() => {
        dispatch(getLessons());
    }, [dispatch])

    const createNewLesson = (e) => {
        e.preventDefault();
        dispatch(showLessonPopup());
    };

    const toggleFeatured = (lesson) => (e) => {
        e.preventDefault();
        dispatch(
            updateLesson({
                ...lesson,
                featured: !lesson.featured,
            })
        );
    }

    const editLessonClick = (lesson) => (e) => {
        e.preventDefault();
        dispatch(showLessonPopup(lesson));
    }

    const deleteLessonClick = (lesson) => (e) => {
        e.preventDefault();
        setSelected(lesson);
        setShowDelete(true);
    }

    const hideDeletePopup = (e) => {
        e.preventDefault();
        setShowDelete(false);
    };

    const renderRows = () => {
        if (list.length === 0) {
            return (
                <tr key="0">
                    <td colSpan="4">No data</td>
                </tr>
            );
        }
        return list.map((lesson, index) => {
            return (
                <tr key={index}>
                    <td>{index + 1}</td>
                    <td><img alt={lesson.image} src={lesson.image} className="thumbnail"></img></td>
                    <td>{lesson.name}</td>
                    <td>{DifficultyFromValue(lesson.difficulty)?.label}</td>
                    <td>{lesson.course}</td>
                    <td>{lesson.program}</td>
                    <td>{StatusFromValue(lesson.status).label}</td>
                    <td className="table-actions">
                        <ul className="action-list">
                            <li className="action-item">
                                <a href="/#" onClick={toggleFeatured(lesson)}>
                                    <Icon 
                                        icon="star" 
                                        fillColor={lesson.featured === true ? "#f1c40f" : "none"} 
                                        color={lesson.featured === true ? "#f1c40f" : "currentColor"} 
                                        />
                                </a>
                            </li>

                            <li className="action-item">
                                <a href="/#" onClick={editLessonClick(lesson)}>
                                    <Icon icon="edit-2" color={"#3498db"} />
                                </a>
                            </li>

                            <li className="action-item">
                                <a href="/#" onClick={deleteLessonClick(lesson)}>
                                    <Icon icon="trash" color={"#dc3545"} />
                                </a>
                            </li>
                        </ul>
                    </td>
                </tr>
            );
        });
    };

    return (
        <div className="card">
            <div className="card-header">
                <h5 className="card-title">Lessons</h5>

                <div className="card-actions">
                    <a href="/#" className="btn btn-primary" onClick={createNewLesson}>
                        <Icon size="16" icon="plus" />
                        Create New Lesson
                    </a>
                </div>
            </div>

            <table className="table">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Image</th>
                        <th className="sortable sort-asc">Lesson Name</th>
                        <th>Difficulty</th>
                        <th>Course</th>
                        <th>Program</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {renderRows()}
                </tbody>
            </table>

            <LessonPopup />

            <InlinePopup
                isShowing={showingDelete}
                hide={hideDeletePopup}
                title="Delete Lesson"
                message="Are you sure you want to delete this lesson?"
                action={deleteLesson(selected)} />
        </div>
    );
}