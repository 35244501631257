import Icon from './Icon';
import { useDispatch } from "react-redux";

const InlinePopup = ({isShowing, hide, title, message, action, negativeAction}) => {
    let dispatch = useDispatch();

    const close = (e) => {
        hide(e);
    };

    const positiveClick = (e) => {
        e.preventDefault();
        close(e);
        console.log(action);
        dispatch(action);
    };

    const negativeClick = (e) => {
        e.preventDefault();
        close(e);
        dispatch(negativeAction);
    };

    const positiveLabel = negativeAction ? 'Yes' : 'OK';

    return (
        <div className={ "popup" + (isShowing ? " popup-show" : "")}>
            <div className="popup-overlay"></div>

            <div className="popup-wrapper">
                <div className="popup-content">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">{title}</h5>
                            <div>
                                <a href="/#" onClick={close}>
                                    <Icon icon="x"/>
                                </a>     
                            </div>
                        </div>

                        <div className="card-body">
                            <p>{message}</p>

                            <div className="actions">

                                { negativeAction &&
                                    <a href="/#" onClick={negativeClick} className="btn btn-info">
                                        No        
                                    </a>
                                }
                                
                                <a href="/#" onClick={positiveClick} className="btn btn-primary">
                                    { positiveLabel }
                                </a>
                            </div>                
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InlinePopup;