import { useDispatch } from "react-redux";
import { confirmLogout } from "../store/uiSlice";
import { logout } from "../store/authSlice";
import Icon from "./common/Icon";

const Navbar = () => {

    let dispatch = useDispatch();

    const onLogout = (e) => {
        e.preventDefault();
        dispatch(confirmLogout(logout.type));
    };

    return (
        <nav className="navbar navbar-expand-lg">
            <form className="my-2 my-lg-0">
                <div className="input-group nav-search">
                    <input className="form-control" type="search" placeholder="Search" aria-label="Search" />
                    <button className="btn" type="button">
                        <Icon size="18" icon="search" />
                    </button>
                </div>
                
            </form>


            <div className="nav-actions">
                <a onClick={ onLogout } className="btn btn-nav" href="/#">
                    <Icon size="18" icon="power" />
                </a>
            </div>
        </nav>
    );
};

export default Navbar;