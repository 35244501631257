import { createSlice } from "@reduxjs/toolkit";
import * as actions from './api';

export const fileSlice = createSlice({
    name: 'file',
    initialState: {
        loading: false,
        error: null,
        list: [],
        file: null,
        popup: false,
    },
    reducers: {
        filesRequested: (state, action) => {
            state.loading = true;
        },
        filesReceived: (state, action) => {
            state.loading = false;
            state.list = action.payload.data;
        },
        filesError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        fileRequested: (state, action) => {
            state.loading = true;
        },
        fileCreated: (state, action) => {
            state.loading = false;
            state.popup = false;
            state.file = action.payload;
            console.log(state.file);
            state.form = null;
        },
        fileUpdated: (state, action) => {
            state.loading = false;
            state.popup = false;
            state.form = null;
            const index = state.list.findIndex( file => file.id === action.payload.id );
            state.list[index] = action.payload;
        },
        fileDeleted: (state, action) => {
            state.loading = false;
            state.list = state.list.filter( file => file.id !== action.payload.id );
        },
        fileError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        showFilePopup: (state, action) => {
            state.popup = true;
            state.form = action.payload;
        },
        closePopup: (state, action) => {
            state.popup = false;
            state.form = null;
        },
        fileReset: (state, action) => {
            state.file = null;
        }
    }
});

const {
    filesRequested,
    filesReceived,
    filesError,
    fileRequested,
    fileError,
    fileCreated,
    fileUpdated,
    fileDeleted,
    showFilePopup,
    closePopup,
    fileReset,
} = fileSlice.actions;

const getFiles = () => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken;
    dispatch(
        actions.apiCallBegan(
            {
                url: '/file',
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                },
                onStart: filesRequested.type,
                onSuccess: filesReceived.type,
                onError: filesError.type
            }
        )
    );
}

const createFile = (data) => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken;
    dispatch(
        actions.uploadCallBegan(
            {
                url: '/file/upload',
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                },
                data: data,
                onStart: fileRequested.type,
                onSuccess: fileCreated.type,
                onError: fileError.type
            }
        )
    );
}

const updateFile = (data) => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken;

    dispatch(
        actions.apiCallBegan(
            {
                url: '/file/' + data.id,
                method: 'PUT',
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                },
                data: data,
                onStart: fileRequested.type,
                onSuccess: fileUpdated.type,
                onError: fileError.type
            }
        )
    );
}

const deleteFile = (data) => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken;

    dispatch(
        actions.apiCallBegan(
            {
                url: '/file/' + data.id,
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                },
                onStart: fileRequested.type,
                onSuccess: fileDeleted.type,
                onError: fileError.type
            }
        )
    );
}

const reset = () => (dispatch, getState) => {
    dispatch(fileReset);
}

export { 
    getFiles, 
    createFile, 
    updateFile, 
    deleteFile, 
    reset,
    showFilePopup, 
    closePopup
};

export default fileSlice.reducer;