import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closePopup, createLesson, updateLesson } from '../../store/lessonSlice';
import { createFile, reset } from '../../store/fileSlice';
import Icon from "../common/Icon";
import Loading from '../common/Loading';
import Dropdown from '../common/Dropdown';
import { useFormik } from 'formik';
import { getCourses } from "../../store/courseSlice";
import { Statuses, Difficulties } from '../../domain/Constants';

const LessonPopup = () => {

    let loading = useSelector(state => state.lesson.loading);
    let popup = useSelector(state => state.lesson.popup);
    let form = useSelector(state => state.lesson.form);
    let courseList = useSelector(state => state.course.list);
    let courseOptions = courseList.map((item) => { return { value: `${item.id}`, label: item.id + "-" + item.name } }) || [];
    let file = useSelector(state => state.file.file);
    let fileRef = useRef();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCourses());
    }, [dispatch])

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            content: form ? form.content : '',
            course_id: form ? `${form.course_id}` : '',
            imageId: form ? form.imageId : null,
            name: form ? form.name : '',
            difficulty: form ? form.difficulty : Difficulties[0].value,
            featured: form ? form.featured : '',
            status: form ? form.status : Statuses[0].value,
        },
        validate: values => {
            const errors = {};
            if (!values.name || values.name.length === 0) {
                errors.name = 'Required';
            }

            if (!values.content || values.content.length === 0) {
                errors.content = 'Required';
            }

            if (!values.difficulty || values.difficulty.length === 0) {
                errors.difficulty = 'Required';
            }

            return errors;
        },
        onSubmit: (values, { resetForm }) => {
            onSubmit(values);
            fileRef.current.value = null;
            resetForm();
        }
    });

    useEffect(() => {
        let imageId = file?.id;
        formik.setFieldValue("imageId", imageId);
    }, [file]);

    const onUploadFile = (event) => {
        let file = event.target.files[0];
        dispatch(
            createFile(
                file
            )
        );
    }

    const close = (e) => {
        e.preventDefault();
        dispatch(closePopup());
        dispatch(reset);
        fileRef.current.value = null;
    };

    const onSubmit = (values) => {
        if (form) {
            dispatch(updateLesson({
                id: form.id,
                content: values.content,
                course_id: parseInt(values.course_id),
                imageId: values.imageId,
                name: values.name,
                difficulty: values.difficulty,
                featured: values.featured,
                status: values.status,
            }));
            return;
        }

        dispatch(createLesson({
            content: values.content,
            course_id: parseInt(values.course_id),
            imageId: values.imageId,
            name: values.name,
            difficulty: values.difficulty,
            status: values.status,
        }));
    }

    return (
        <div className={"popup popup-large" + (popup ? " popup-show" : "")}>
            <div className="popup-overlay"></div>

            <div className="popup-wrapper">
                <div className="popup-content">
                    <div className="card">
                        <div className="card-header">
                            {form ?
                                <h5 className="card-title">Edit Lesson</h5>
                                :
                                <h5 className="card-title">Create New Lesson</h5>
                            }
                            <div>
                                <a href="/#" onClick={close}>
                                    <Icon icon="x" />
                                </a>

                            </div>
                        </div>

                        <div className="card-body">
                            <form onSubmit={formik.handleSubmit} >
                                <div className="form-group">
                                    <label>Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="name"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.name}
                                    />
                                </div>

                                <div className="form-group">
                                    <label>Difficulty</label>
                                    <Dropdown
                                        options={Difficulties}
                                        name="difficulty"
                                        readOnly={form}
                                        onChange={selected => formik.handleChange("difficulty")(selected.value)}
                                        onBlur={formik.handleBlur}
                                        value={Difficulties.filter((option) => option.value === formik.values.difficulty)[0]}
                                    />
                                </div>

                                <div className="form-group">
                                    <label>Course</label>
                                    <Dropdown
                                        options={courseOptions}
                                        name="course_id"
                                        onChange={selected => formik.handleChange("course_id")(selected.value)}
                                        onBlur={formik.handleBlur}
                                        value={courseOptions.filter((option) => option.value === formik.values.course_id)[0] || null}
                                    />
                                </div>

                                <div className="form-group">
                                    <label>Content</label>
                                    <textarea
                                        type="text"
                                        className="form-control"
                                        name="content"
                                        rows={10}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.content}
                                    />
                                </div>
                                
                                <div className="form-group">
                                    <label>Image</label>
                                    <input
                                        type="file"
                                        className="form-control"
                                        name="image"
                                        ref={fileRef}
                                        readOnly={form}
                                        onChange={onUploadFile}
                                    />
                                </div>

                                <div className="form-group">
                                    <label>Status</label>
                                    <Dropdown
                                        options={Statuses}
                                        name="status"
                                        onChange={selected => formik.handleChange("status")(selected.value)}
                                        onBlur={formik.handleBlur}
                                        value={Statuses.filter((option) => option.value === formik.values.status)[0]}
                                    />
                                </div>

                                <div className="actions">
                                <button
                                    type="submit"
                                    className="btn btn-primary">
                                    {form ? "Update" : "Create"}
                                </button>
                                <div className="fill"></div>

                                {loading &&
                                    <Loading />
                                }
                            </div>
                            </form>

                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LessonPopup;