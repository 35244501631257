import Dashboard from "./dashboard/Dashboard";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import { Route, Switch, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import ConfirmPopup from './common/ConfirmPopup';
import Programs from "./program/Programs";
import ProgramPopup from "./program/ProgramPopup";
import Courses from "./course/Courses";
import CoursePopup from "./course/CoursePopup";
import Lessons from "./lesson/Lessons";
import LessonPopup from "./lesson/LessonPopup";
import System from "./system/System";
import AppConfig from "./app_config/AppConfig";

const AdminPortal = () => {
    let history = useHistory();

    useSelector(state => {
        let accessToken = state.auth.accessToken;
        if (accessToken == null) {
            history.push('/login');
            return;
        }
    });

    return (
        <div className="wrapper">
            <Sidebar />

            <div className="main">
                <Navbar />

                <div className="content">
                    <Switch>
                        <Route path="/app_config" component={ AppConfig } />
                        <Route path="/programs" component={ Programs } />
                        <Route path="/courses" component={ Courses } />
                        <Route path="/lessons" component={ Lessons } />
                        <Route path="/system" component={ System } />
                        <Route path="/" component={Dashboard} />
                    </Switch>
                </div>


                <div className="footer">
                </div>

                <ConfirmPopup />
                <ProgramPopup />
                <CoursePopup />
                <LessonPopup />
            </div>


        </div>
    );
};

export default AdminPortal;

