import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showCoursePopup, getCourses, deleteCourse, updateCourse } from '../../store/courseSlice';
import Icon from '../common/Icon';
import CoursePopup from './CoursePopup';
import InlinePopup from '../common/InlinePopup';
import { StatusFromValue, DifficultyFromValue } from '../../domain/Constants';

export default function Courses() {

    let [showingDelete, setShowDelete] = useState(false);
    let [selected, setSelected] = useState(null);
    let list = useSelector(state => state.course.list);

    let dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCourses());
    }, [dispatch])

    const createNewCourse = (e) => {
        e.preventDefault();
        dispatch(showCoursePopup());
    };

    const toggleFeatured = (course) => (e) => {
        e.preventDefault();
        dispatch(
            updateCourse({
                ...course,
                featured: !course.featured,
            })
        );
    }

    const editCourseClick = (course) => (e) => {
        e.preventDefault();
        dispatch(showCoursePopup(course));
    }

    const deleteCourseClick = (course) => (e) => {
        e.preventDefault();
        setSelected(course);
        setShowDelete(true);
    }

    const hideDeletePopup = (e) => {
        e.preventDefault();
        setShowDelete(false);
    };

    const renderRows = () => {
        if (list.length === 0) {
            return (
                <tr key="0">
                    <td colSpan="4">No data</td>
                </tr>
            );
        }
        return list.map((course, index) => {
            return (
                <tr key={index}>
                    <td>{index + 1}</td>
                    <td><img alt={course.image} src={course.image} className="thumbnail"></img></td>
                    <td>{DifficultyFromValue(course.difficulty)?.label}</td>
                    <td>{course.name}</td>
                    <td>{course.program}</td>
                    <td>{StatusFromValue(course.status).label}</td>
                    <td className="table-actions">
                        <ul className="action-list">
                            <li className="action-item">
                                <a href="/#" onClick={toggleFeatured(course)}>
                                    <Icon 
                                        icon="star" 
                                        fillColor={course.featured === true ? "#f1c40f" : "none"} 
                                        color={course.featured === true ? "#f1c40f" : "currentColor"} 
                                        />
                                </a>
                            </li>

                            <li className="action-item">
                                <a href="/#" onClick={editCourseClick(course)}>
                                    <Icon icon="edit-2" color={"#3498db"} />
                                </a>
                            </li>

                            <li className="action-item">
                                <a href="/#" onClick={deleteCourseClick(course)}>
                                    <Icon icon="trash" color={"#dc3545"} />
                                </a>
                            </li>
                        </ul>
                    </td>
                </tr>
            );
        });
    };

    return (
        <div className="card">
            <div className="card-header">
                <h5 className="card-title">Courses</h5>

                <div className="card-actions">
                    <a href="/#" className="btn btn-primary" onClick={createNewCourse}>
                        <Icon size="16" icon="plus" />
                        Create New Course
                    </a>
                </div>
            </div>

            <table className="table">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Image</th>
                        <th>Difficulty</th>
                        <th className="sortable sort-asc">Course Name</th>
                        <th>Program</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {renderRows()}
                </tbody>
            </table>

            <CoursePopup />

            <InlinePopup
                isShowing={showingDelete}
                hide={hideDeletePopup}
                title="Delete Course"
                message="Are you sure you want to delete this course?"
                action={deleteCourse(selected)} />
        </div>
    );
}