import { createSlice } from "@reduxjs/toolkit";
import * as actions from './api';

export const lessonSlice = createSlice({
    name: 'lesson',
    initialState: {
        loading: false,
        error: null,
        list: [],
        form: null,
        popup: false,
    },
    reducers: {
        lessonsRequested: (state, action) => {
            state.loading = true;
        },
        lessonsReceived: (state, action) => {
            state.loading = false;
            state.list = action.payload.data;
        },
        lessonsError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        lessonRequested: (state, action) => {
            state.loading = true;
        },
        lessonCreated: (state, action) => {
            state.loading = false;
            state.popup = false;
            state.list = [action.payload, ...state.list];
            state.form = null;
        },
        lessonUpdated: (state, action) => {
            state.loading = false;
            state.popup = false;
            state.form = null;
            const index = state.list.findIndex( lesson => lesson.id === action.payload.id );
            state.list[index] = action.payload;
        },
        lessonDeleted: (state, action) => {
            state.loading = false;
            state.list = state.list.filter( lesson => lesson.id !== action.payload.id );
        },
        lessonError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        showLessonPopup: (state, action) => {
            state.popup = true;
            state.form = action.payload;
        },
        closePopup: (state, action) => {
            state.popup = false;
            state.form = null;
        }
    }
});

const {
    lessonsRequested,
    lessonsReceived,
    lessonsError,
    lessonRequested,
    lessonError,
    lessonCreated,
    lessonUpdated,
    lessonDeleted,
    showLessonPopup,
    closePopup,
} = lessonSlice.actions;

const getLessons = () => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken;
    dispatch(
        actions.apiCallBegan(
            {
                url: '/lessons',
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                },
                onStart: lessonsRequested.type,
                onSuccess: lessonsReceived.type,
                onError: lessonsError.type
            }
        )
    );
}

const createLesson = (data) => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken;

    dispatch(
        actions.apiCallBegan(
            {
                url: '/lessons',
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                },
                data: data,
                onStart: lessonRequested.type,
                onSuccess: lessonCreated.type,
                onError: lessonError.type
            }
        )
    );
}

const updateLesson = (data) => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken;

    dispatch(
        actions.apiCallBegan(
            {
                url: '/lessons/' + data.id,
                method: 'PUT',
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                },
                data: data,
                onStart: lessonRequested.type,
                onSuccess: lessonUpdated.type,
                onError: lessonError.type
            }
        )
    );
}

const deleteLesson = (data) => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken;

    dispatch(
        actions.apiCallBegan(
            {
                url: '/lessons/' + data.id,
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                },
                onStart: lessonRequested.type,
                onSuccess: lessonDeleted.type,
                onError: lessonError.type
            }
        )
    );
}

export { 
    getLessons, 
    createLesson, 
    updateLesson, 
    deleteLesson, 
    showLessonPopup, 
    closePopup 
};

export default lessonSlice.reducer;