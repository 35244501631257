import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { login } from '../store/authSlice';
import Loading from './common/Loading';
import { Formik } from 'formik';

export default function Login() {

    let history = useHistory();

    useSelector(state => {
        let accessToken = state.auth.accessToken;
        if (accessToken != null) {
            goToAdminPortal();
            return;
        }
    });


    let loading = useSelector(state => state.auth.loading);

    const dispatch = useDispatch();

    const goToAdminPortal = () => {
        history.push('/');
    }

    const onLogin = (values) => {
        dispatch(
            login(
                values['username'],
                values['password'],
            )
        );
    }

    return (
        <div className="wrapper-login">
            <div className="card card-login">
                <div className="card-body">
                    <h3 className="card-title mb-3">Pro English</h3>
                    <Formik
                        initialValues={{ username: '', password: '', }}
                        validate={values => {
                            const errors = {};
                            if (!values.username || values.username.length === 0) {
                                errors.username = 'Required';
                            }
                            if (!values.password || values.username.password === 0) {
                                errors.password = 'Required';
                            }
                            return errors;
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            onLogin(values);
                        }}
                    >
                        {({
                            values,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label>Username</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="username"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.username}
                                    />
                                </div>

                                <div className="form-group">
                                    <label>Password</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        name="password"
                                        value={values.password}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                </div>

                                <div className="actions">
                                    <button className="btn btn-primary" type="submit">Login</button>
                                    <div className="fill"></div>

                                    {loading &&
                                        <Loading />
                                    }
                                </div>

                            </form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
}