import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    messages: [],
    isConnecting: false,
    isConnected: false,
}

const websocketSlice = createSlice({
    name: "websocket",
    initialState,
    reducers: {
        connect: (state, action) => {
            state.isConnecting = true;
            state.isConnected = false;
        },
        connected: (state, action) => {
            state.isConnecting = false;
            state.isConnected = true;
        },
        receiveMessage: (state, action) => {
            state.messages.push(action.payload.message);
        }
    }
});

export const websocketActions = websocketSlice.actions;

export default websocketSlice.reducer;