import { createSlice } from "@reduxjs/toolkit";
import * as actions from './api';

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        user: null,
        accessToken: null,
        loading: false,
        error: null
    },
    reducers: {
        loginRequested: (state, action) => {
            state.loading = true;
        },
        loginReceived: (state, action) => {
            state.loading = false;
            state.accessToken = action.payload.accessToken;
        },
        loginError: (state, action) => {
            state.loading = false;
            state.error = action.payload.error;
        },
        logout: state => {
            state.accessToken = null
        }
    }
});

const {
    loginRequested,
    loginReceived,
    loginError,
    logout
} = authSlice.actions;

const login = (username, password) => (dispatch) => {
    dispatch(
        actions.apiCallBegan(
            {
                url: "/auth/signin",
                method: "POST",
                data: {
                    username: username,
                    password: password
                },
                onStart: loginRequested.type,
                onSuccess: loginReceived.type,
                onError: loginError.type
            }
        )
    );
}

export { login, logout };

export default authSlice.reducer;